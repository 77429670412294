<template>
  <section>
    <products-main />
  </section>
</template>

<script>
export default {
  name: 'Products',
  components: {
    ProductsMain: () => import('@/components/Products/ProductsMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
